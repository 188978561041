import React, { useEffect } from 'react';
import { addUser, initiateInstalmentPayment, initiatePayment } from '../services/transactionServices';
import { useRouter } from 'next/router';

function loadScript(src) {
    // eslint-disable-next-line no-undef
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}

const Razorpay = ({ paymentInfo, setPayment, cashback, isInstalment = false }) => {
    const router = useRouter();

    const { medium, ...restParams } = router.query;

    const _params = restParams ? Object.entries(restParams) : '';

    const _utmParams = _params
        .filter((item) => item[0] !== 'name')
        .filter((item) => item[0] !== 'id')
        .map((item) => `&${item[0]}=${item[1]}`)
        .join('');

    async function displayRazorpay() {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        // Instalment payload
        let payload = {
            utm: {},
        };

        if (isInstalment) {
            payload = {
                ...paymentInfo,
            };
            delete payload.utm;
        } else {
            payload = {
                name: paymentInfo.name,
                email: paymentInfo.email,
                mobile: paymentInfo.mobile,
                iam: paymentInfo.iam,
                college: paymentInfo.college,
                company: paymentInfo.company,
                amount: paymentInfo.amount,
                batchId: paymentInfo.batchId,
                workshopId: paymentInfo?.workshopId,
                discountId: paymentInfo?.discountId,
                currency: paymentInfo?.currency,
                couponId: paymentInfo?.couponId,
                recording: paymentInfo?.recording,
                referCode: paymentInfo?.referCode,
                cashback: cashback,
                utm: {
                    source: '',
                    medium: '',
                    campaign: '',
                    publisher: '',
                    term: '',
                },
            };
        }

        if (!isInstalment) {
            if (payload.iam === 'graduate' || payload.iam === 'student') {
                delete payload.company;
            }
            if (payload.iam === 'professional') {
                delete payload.college;
            }

            if (!payload.discountId) {
                delete payload.discountId;
            }
        }
        if (restParams.utm_source && !isInstalment) {
            payload.utm.source = String(restParams.utm_source).toLowerCase();
            payload.utm.medium = String(restParams.utm_medium).toLowerCase();
            payload.utm.campaign = String(restParams.utm_campaign).toLowerCase();
            payload.utm.publisher = String(restParams.utm_publisher).toLowerCase();
            payload.utm.term = String(restParams.utm_term).toLowerCase();
        } else {
            payload.utm = null;
        }

        let data = null;


        if (isInstalment) {
            data = await initiateInstalmentPayment(payload).then(({ data }) => data.data.order);
        } else {
            await addUser(payload);
            data = await initiatePayment(payload).then(({ data }) => data.data.order);
        }
        setPayment(false);
        const options = {
            key: process.env.NEXT_PUBLIC_RAZORPAY_ID,
            currency: 'INR',
            amount: isInstalment ? paymentInfo.instalmentAmount : paymentInfo.amount.toString(),
            order_id: data?.rzpOrderId,
            name: 'KAARWAN EDUVENTURES PVT LTD',
            description: paymentInfo?.Workshop?.name,
            image: 'https://cdn.razorpay.com/logos/GPKyOqzis6SPB4_large.jpg',
            headers: { 'Content-Type': 'application/json' },
            handler: function (response) {
                setPayment(false);
                localStorage.setItem("allowLogin", true)
                router.push(
                    '/payments/success?paymentId=' +
                    response.razorpay_order_id +
                    `${isInstalment ? '&msgId=' + paymentInfo.successMessageId : ''}${medium ? '&medium=' + medium : ''
                    }${_utmParams ? _utmParams : ''}`
                );
            },
            modal: {
                ondismiss: function () {
                    setPayment(false);
                },
            },
            prefill: {
                name: paymentInfo.name,
                email: paymentInfo.email,
                contact: paymentInfo.mobile,
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    useEffect(() => {
        loadScript();
        displayRazorpay();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>{''}</div>;
};

export default Razorpay;