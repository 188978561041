import React, { useState, useCallback, useEffect } from 'react';
import validator from 'validator';
import Razorpay from '../../Razorpay';
import PhoneInput from 'react-phone-input-2';
import { useRouter } from 'next/router';

const InstalmentForm = (props) => {
    const { instalmentData, checkboxRef, currency } = props;
    const router = useRouter();
    const { query } = router;
    const [payment, setPayment] = useState(false);

    const [payload, setPayload] = useState({
        wsId: '',
        batchId: '',
        orderId: null,
        instalmentId: '',
        idsId: [],
        instalmentAmount: 0,
        amount: 0,
        currency: 'INR',
        name: '',
        email: '',
        mobile: '',
        successMessageId: '',
    });

    const [user, setUser] = useState({
        name: '',
        email: '',
        mobile: '',
        dialCode: '',
        country: '',
    });

    const [error, setError] = useState({
        name: false,
        email: false,
        mobile: false,
    });

    const [validationError, setValidationError] = useState({
        email: false,
        mobile: false,
    });

    const [showingError, setShowingError] = useState(false);
    const [isMobileValid, setIsMobileValid] = useState(true);

    const submitHandle = async (e) => {
        e.preventDefault();

        let successMessageId = '';
        const _selectedInstalment = selectedAmount.filter((i) => i.selected);

        if (
            (_selectedInstalment.length === 1 && _selectedInstalment[0].part === 1) ||
            _selectedInstalment.length === instalmentData.instalments.length
        ) {
            successMessageId = '1';
        }

        if (_selectedInstalment.length === 1 && _selectedInstalment[0].part !== 1) {
            successMessageId = `${_selectedInstalment[0]?.part}`;
        }

        if (_selectedInstalment.length === 2 && _selectedInstalment.length !== instalmentData.instalments.length) {
            successMessageId = `${_selectedInstalment[0]?.part}${_selectedInstalment[1]?.part}`;
        }

        const isInstalmentSelected = selectedAmount.filter((i) => i?.selected).length;
        if (!isInstalmentSelected) {
            setShowingError(true);
            return;
        }

        Object.entries(user).forEach((object) => {
            if (user[object[0]] === '') {
                setError((prevState) => ({ ...prevState, [object[0]]: true }));
            } else {
                setError((prevState) => ({ ...prevState, [object[0]]: false }));
            }
        });
        setShowingError(false);

        if (user.dialCode === '91' && user.mobile.length < 10) {
            setIsMobileValid(false);
            return;
        }

        if (
            user.name &&
            user.email &&
            user.mobile &&
            validationError.email === false &&
            validationError.mobile === false
        ) {
            setPayment(true);

            const _ids = selectedAmount.filter((item) => item?.selected).map((item) => item?.id);
            const _instalmentAmount = selectedAmount
                .filter((i) => i?.selected)
                .reduce((total, cur) => total + cur.amount, 0);
            const _instalmentAmountUsd = selectedAmount
                .filter((i) => i?.selected)
                .reduce((total, cur) => total + cur.usdAmount, 0);

            const _payload = {
                amount: instalmentData?.totalAmount,
                batchId: instalmentData?.batchId,
                email: user.email,
                idsId: _ids,
                instalmentAmount: currency === 'USD' ? _instalmentAmountUsd : _instalmentAmount,
                mobile: `+${user.dialCode} ${user.mobile}`,
                name: user.name,
                wsId: instalmentData?.wsId,
                instalmentId: instalmentData?.instalmentId,
                orderId: null,
                successMessageId: successMessageId,
                wsName: instalmentData?.Workshop?.name,
                currency: currency
            };

            handleInitiateCheckout(_payload);
            setPayload(_payload);
        }
    };

    const inputChangeHandle = (event, name) => {
        const { value } = event.target;

        if (name === 'mobile') {
            setUser((prevState) => ({
                ...prevState,
                [name]: String(value).slice(0, 10),
            }));
        } else {
            setUser((prevState) => ({ ...prevState, [name]: value }));
        }
        if (name === 'email') {
            const isValid = validator.isEmail(value);

            setValidationError((prevState) => ({
                ...prevState,
                email: !isValid ? true : false,
            }));
        }

        if (name === 'mobile') {
            if (String(value).length < 10 || value === '') {
                setValidationError((prevState) => ({
                    ...prevState,
                    mobile: true,
                }));
            } else {
                setValidationError((prevState) => ({
                    ...prevState,
                    mobile: false,
                }));
            }
        }
    };

    const handleInputBlur = (e) => {
        const { name } = e.target;
        e.target.value &&
            setError((prevState) => ({
                ...prevState,
                [name]: false,
            }));

        if (name === 'mobile' && String(e.target.value).length === 15) {
            setIsMobileValid(true);
        }

        setPayment(false);
    };

    const paymentFormHandle = () => {
        checkboxRef.current.checked = false;
    };

    const handleInitiateCheckout = useCallback((data) => {
        if (typeof window !== 'undefined') {
            window.gtag('event', 'add_payment_info', {
                transaction_id: `K_${new Date().getTime()}`,
                currency: 'INR',
                value: data.instalmentAmount,
                user_data: {
                    email_address: data.email,
                    phone_number: data.mobile,
                    address: {
                        first_name: data.name,
                    },
                },
                items: [
                    {
                        item_name: data?.wsName,
                        quantity: 1,
                        price: data.instalmentAmount,
                        item_category: data?.batchId,
                        item_brand: data?.batchId,
                    },
                ],
                event_category: 'button',
                event_label: 'add_payment_info',
            });

            !!window.fbq &&
                window.fbq('track', 'InitiateCheckout', {
                    content_name: data?.wsName,
                    content_category: data?.batchId,
                    content_id: data?.batchId,
                    content_type: 'Product',
                    contents: [
                        {
                            id: `K_${new Date().getTime()}`,
                            quantity: 1,
                        },
                    ],
                    value: data.instalmentAmount,
                    currency: 'INR',
                });
        }
    }, []);

    const [selectedAmount, setSelectedAmount] = useState([]);
    useEffect(() => {
        if (instalmentData?.instalments && instalmentData?.instalments.length) {
            const _defaultSelect = query.instalmentNumber ? parseInt(query.instalmentNumber, 10) : 1;
            const _amounts = Array.from(instalmentData?.instalments).map((item, ind) => ({
                id: item?.id,
                amount: item?.amount,
                selected: ind + 1 === _defaultSelect ? true : false,
                name: item?.name,
                part: ind + 1,
                usdAmount: item?.usdAmount
            }));

            setSelectedAmount(_amounts);
        }
    }, [instalmentData?.instalments, query.instalmentNumber]);

    const amountSelectHandle = (e, price, id, ind) => {
        if (e.target.checked) {
            let _updatedData = selectedAmount;
            _updatedData[ind].selected = true;
            setSelectedAmount([..._updatedData]);
            setShowingError(false);
        } else {
            let _updatedData = selectedAmount;
            _updatedData[ind].selected = false;
            setSelectedAmount([..._updatedData]);
        }
    };

    const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave?';

        event.returnValue = message;
        return message;
    };

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);



    return (
        <div className='main-form-container'>
            <form className='payment-form-container' method='post' noValidate>
                <div className='form-content'>
                    <button
                        className='btn-close-form align-items-center justify-content-center'
                        type='button'
                        onClick={paymentFormHandle}
                    >
                        <img src='/kaarwan/icons/arrow-left.svg' alt='go-back' />
                    </button>

                    <h1 className='title'>Payment Details</h1>
                    <p className='title-underline'></p>

                    <div className='form-container'>
                        {/* Name Field */}
                        <div className='form-field-container d-flex justify-content-between'>
                            <label htmlFor='name' className='field-label'>
                                Full Name
                            </label>
                            <div className='input-field-container'>
                                <div className='input-wrapper'>
                                    <input
                                        type='text'
                                        className={`input-field ${error.name && 'border-error'}`}
                                        id='name'
                                        name='name'
                                        onChange={(event) => inputChangeHandle(event, 'name')}
                                        onBlur={handleInputBlur}
                                    />
                                </div>
                                <div className='field-description'>This will be printed on the certificate!</div>
                                <div className='error-message'>{error.name && 'This field is required'}</div>
                            </div>
                        </div>

                        {/* Email Field */}
                        <div className='form-field-container d-flex justify-content-between'>
                            <label htmlFor='email' className='field-label'>
                                Email
                            </label>
                            <div className='input-field-container'>
                                <div className='input-wrapper'>
                                    <input
                                        type='email'
                                        className={`input-field ${error.email && 'border-error'} `}
                                        id='email'
                                        name='email'
                                        onChange={(event) => inputChangeHandle(event, 'email')}
                                        onBlur={handleInputBlur}
                                    />
                                </div>
                                <div className='field-description'></div>
                                <div className='error-message'>
                                    {error.email && 'This field is required'}
                                    {!error.email && validationError.email && 'Invalid email address'}
                                </div>
                            </div>
                        </div>

                        {/* Phone Number Field */}
                        <div className='form-field-container d-flex justify-content-between'>
                            <label htmlFor='mobile' className='field-label'>
                                Phone
                            </label>
                            <div className='kwn-mobile-number-wrapper payment-form'>
                                <PhoneInput
                                    country='in'
                                    className={`input-field ${error.mobile && 'border-error'}  `}
                                    inputProps={{
                                        name: 'mobile',
                                        required: true,
                                    }}
                                    enableSearch
                                    copyNumbersOnly
                                    onBlur={handleInputBlur}
                                    countryCodeEditable={false}
                                    onChange={(value, country) =>
                                        setUser((prevState) => ({
                                            ...prevState,
                                            dialCode: country.dialCode,
                                            mobile: removeCountryCode(value, country.dialCode),
                                            country: String(country.countryCode).toUpperCase(),
                                        }))
                                    }
                                />
                                <div className='input-field-container m-0 p-0'>
                                    <div className='error-message'>
                                        {error.mobile && 'This field is required'}
                                        {!error.mobile && !isMobileValid && 'Enter your 10-digit Mobile number'}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {selectedAmount && selectedAmount.length
                            ? Array.from(selectedAmount).map((item, ind) => (
                                <div className='form-field-container d-flex justify-content-between' key={item?.id}>
                                    <label htmlFor='amount' className='field-label'>
                                        Instalment {ind + 1}
                                    </label>
                                    <div className='input-field-container'>
                                        <div className='input-wrapper has-checkbox'>
                                            <input
                                                type='text'
                                                className={`input-field input-disabled fw-bold`}
                                                id='amount'
                                                // defaultValue={`₹ ${item?.amount}`}
                                                defaultValue={currency === 'USD' ? `$${item?.usdAmount}` : `₹ ${item?.amount}`}
                                                readOnly
                                                disabled
                                            />
                                            <input
                                                type='checkbox'
                                                checked={item?.selected}
                                                onChange={(e) => amountSelectHandle(e, item?.amount, item?.id, ind)}
                                            />
                                        </div>

                                        {item?.name ? <div className='field-description'>{item?.name}</div> : null}

                                        <div className='error-message'></div>
                                    </div>
                                </div>
                            ))
                            : null}
                    </div>

                    {showingError ? (
                        <span>
                            <small style={{ color: '#FF0000', fontWeight: '400' }}>
                                Note: Please select at least one instalment before proceeding
                            </small>
                        </span>
                    ) : null}
                </div>
                <div className='footer-content d-flex'>
                    <div className='d-flex align-items-center justify-content-center payment-methods'>
                        <img
                            src='https://cdn.razorpay.com/static/assets/pay_methods_branding.png'
                            alt='payment-methods'
                        />
                    </div>
                    <div className='payment-button-container'>
                        <button className='btn-initiate-payment' onClick={submitHandle}>
                            {payment ? (
                                <span className='dotted-loader'>
                                    <div className='spinner'>
                                        <div className='bounce1'></div>
                                        <div className='bounce2'></div>
                                        <div className='bounce3'></div>
                                    </div>
                                </span>
                            ) : (
                                <>
                                    Pay {currency === 'USD' ? '$' : '₹'}
                                    {currency === 'USD' ? <>{selectedAmount
                                        .filter((i) => i.selected)
                                        .reduce((total, cur) => total + cur.usdAmount, 0)}</> : <>{selectedAmount
                                            .filter((i) => i.selected)
                                            .reduce((total, cur) => total + cur.amount, 0)}</>}
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </form>
            {payment ? <Razorpay paymentInfo={payload} setPayment={setPayment} isInstalment={true} /> : null}
        </div>
    );
};

export default InstalmentForm;

export function removeCountryCode(paragraph, textToRemove) {
    // Use a regular expression to match the first occurrence of the specified text
    const regex = new RegExp(textToRemove);

    // Replace the first occurrence with an empty string
    const cleanedParagraph = paragraph.replace(regex, '');

    return cleanedParagraph;
}
